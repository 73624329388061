import React, { PureComponent } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import * as constants from '../utils/utils';


class Viewer extends PureComponent {
    render() {
        if (this.props.personioData.length === 0) {
            return '';
        }
  
        return <ReactTable
            data={this.props.personioData}
            columns={constants.columns}
        />
    }
}

export default Viewer;
