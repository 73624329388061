
export const dateTimeFormat = "M/D/YYYY h:m A";

export const dateFormat = "YYYY-MM-DD";

export const timeFormat = "HH:mm";

export const schema = {
    'No.': {
        prop: 'personioId',
        type: Number
    },
    'Name': {
        prop: 'name',
        type: String
    },
    'Time': {
        prop: 'dateTime',
        type: String
    }
};

export const columns = [
    {
        Header: 'Name',
        accessor: 'name',
        minWidth: 300
    }, {
        Header: 'Date',
        accessor: 'date',
        minWidth: 200
    }, {
        Header: 'Time in',
        accessor: 'start_time'
    }, {
        Header: 'Time out',
        accessor: 'end_time'
    }, {
        Header: 'Personio ID',
        accessor: 'employee'
    }
];

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}