import React, { PureComponent } from 'react';
import readXlsxFile from 'read-excel-file';
import Viewer from './Viewer';
import * as moment from 'moment';
import * as constants from '../utils/utils';
import Personio from '../utils/personio';

export class Uploader extends PureComponent {
    state = {
        lists: [],
        message: "",
        startDate: new Date(),
        endDate: new Date(),
        employees: {},
        employeeIds: [],
        personioData: [],
        uploadData: {},
        inProgress: false
    };

    changeHandler = async (el) => {
        this.updateMessage("Upload in progress....");
        this.setState({ personioData: []});
        readXlsxFile(el.target.files[0], { schema: constants.schema }).then(async ({rows, errors}) => {
            if (errors.length > 0) {
                this.updateMessage("There are errors on the uploaded file.");
                return;
            }
            this.setState({lists: rows});
            this.updateMessage("Finished uploading");
            try {
                this.formatData(rows);
                await this.uploadData();
            } catch (error) {
                console.log(error);
                this.updateMessage(error.toString());
                this.setState({ inProgress: false });
            }
        }).catch((errors) => {
            console.log(errors);
            this.updateMessage(`${errors.toString()}. Make sure you are uploading xlsx file/format.`);
        });
    }

    updateMessage = (message) => {
        this.setState({ message });
    }

    formatData = (data) => {
        let newStartDate, newEndDate, employees = {}, employeeIds = [];

        this.updateMessage("Started processing....");
        
        for (let employeeData of data) {
            newStartDate = this.getEarliestDate(newStartDate, employeeData.dateTime);
            newEndDate = this.getLatestDate(newEndDate, employeeData.dateTime);
            employees[employeeData.name] = this.extractAttendanceInfo(employees[employeeData.name], employeeData);
            if (!(employeeIds.includes(employeeData.personioId))) {
                employeeIds.push(employeeData.personioId);
            }
        }
        this.setState({ startDate: newStartDate, endDate: newEndDate, employees, employeeIds});
        this.updateUploadData(employees);
        this.updateMessage("Finished processing");
    }

    extractAttendanceInfo = (employeeOldData, currentEmployeeData) => {

        let { name, personioId, dateTime } = currentEmployeeData,
            employeeDate = moment(dateTime, constants.dateTimeFormat),
            employeeDateFormatted = employeeDate.format(constants.dateFormat),
            employeeTimeFormatted = employeeDate.format(constants.timeFormat),
            employee = {
                name,
                personioId,
                dates: {}
            };

        if (
            employeeOldData === undefined ||
            employeeOldData.length === 0 || 
            (employeeOldData.name !== name)
        ) {
            employee.dates[employeeDateFormatted] = {};
            employee.dates[employeeDateFormatted] = this.defaultTimeData(employee.dates[employeeDateFormatted], employeeTimeFormatted, constants.timeFormat)
            return employee;
        }
        
        employeeOldData.dates[employeeDateFormatted] = (employeeDateFormatted in employeeOldData.dates) ? employeeOldData.dates[employeeDateFormatted]: {};
        
        if (!("in" in employeeOldData.dates[employeeDateFormatted])) {
            employeeOldData.dates[employeeDateFormatted] = this.defaultTimeData(employeeOldData.dates[employeeDateFormatted], employeeTimeFormatted, constants.timeFormat);
            return employeeOldData;
        }
        employeeOldData.dates[employeeDateFormatted]["in"] = this.getEarliestDate(employeeOldData.dates[employeeDateFormatted]["in"], employeeTimeFormatted, constants.timeFormat);
        employeeOldData.dates[employeeDateFormatted]["out"] = this.getLatestDate(employeeOldData.dates[employeeDateFormatted]["out"], employeeTimeFormatted, constants.timeFormat);
        
        return employeeOldData;
    }

    defaultTimeData = (employeeData, time, format) => {
        employeeData["in"] = moment(time, constants.timeFormat);
        employeeData["out"] = moment(moment(time, format).add(1, 'h').format(format), format);
        return employeeData;
    }

    getEarliestDate = (oldDateParam, newDateParam, format = constants.dateTimeFormat) => {
        let { oldDate, currentStartDate } = this.getDates(oldDateParam, newDateParam, format);
        return oldDate.isSameOrBefore(currentStartDate) ? oldDate : currentStartDate;
    }

    getLatestDate = (oldDateParam, newDateParam, format = constants.dateTimeFormat) => {
        let { oldDate, currentStartDate } = this.getDates(oldDateParam, newDateParam, format);
        return oldDate.isSameOrAfter(currentStartDate) ? oldDate : currentStartDate;
    }

    getDates = (oldDate, newDate, format) => {
        oldDate = oldDate !== undefined ? moment(oldDate, format) : moment(newDate, format);
        let currentStartDate = moment(newDate, format);
        return { oldDate, currentStartDate };
    }

    uploadData = async () => {
        let personio = new Personio();
        let { uploadData } = this.state; 
        this.updateMessage("Personio cleanup in progress....");
        this.setState({ inProgress: true });
        await personio.deleteExistingPeriods(this.state.startDate.format(constants.dateFormat), this.state.endDate.format(constants.dateFormat), this.state.employeeIds);
        this.updateMessage("Personio cleanup done");
        this.updateMessage("Personio upload in progress....");
        for (let employee in uploadData) {
            let uploadMessage = await personio.updateAttendance(uploadData[employee]);
            this.updateMessage(`Personio upload completed for ${employee}. Status: ${uploadMessage.message}`);
            if (uploadMessage.status !== 200) {
                break;
            }
        }
        this.setState({ inProgress: false });
    }

    updateUploadData = (personioData) => {
        let attendanceInfo = [],
            forUploadData = {};
        for (let employee in personioData) {
            let { name } = personioData[employee],
                dates = Object.keys(personioData[employee].dates),
                ctr = 0;
            forUploadData[name] = [];
            for (let timeInfo in personioData[employee].dates ) {
                let attendanceInfoObj = {
                    name,
                    date: dates[ctr++],
                    start_time: moment(personioData[employee].dates[timeInfo].in, constants.timeFormat).format(constants.timeFormat),
                    end_time: moment(personioData[employee].dates[timeInfo].out, constants.timeFormat).format(constants.timeFormat),
                    employee: personioData[employee].personioId,
                    break: 60,
                    comment: "Uploaded from biometrics"
                };
                attendanceInfo.push(attendanceInfoObj);
                forUploadData[name].push(attendanceInfoObj)
            }
        }
        this.setState({ personioData: attendanceInfo });
        this.setState({ uploadData: forUploadData });
    }

    componentDidMount() {
        window.onbeforeunload = () => {
            if (this.state.inProgress) {
                return "Still processing";
            }
        }
    }
    
    render() {
        return (
            <div>
                <input type="file" id="excel-file" onChange={this.changeHandler} />
                <p>{this.state.message}</p>
                <Viewer updateMessage={this.updateMessage} personioData={this.state.personioData} />
            </div>
        );
    }
}

export default Uploader;
