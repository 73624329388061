import React from 'react';
import './App.css';
import Uploader from './components/Uploader';
import ErrorHandler from './utils/ErrorHandler';

function App() {
  return (
    <div className="App">
      Upload attendance
      <ErrorHandler>
        <Uploader />
      </ErrorHandler>
    </div>
  );
}
  
export default App;
