import axios from 'axios';

class Personio {

    URL = "https://personio-proxy.lamudi.com/v1/";
    
    headers= {};

    periodData = [];

    authenticate = async () => {
        const authPath = 'auth';
        let res;
        try {
            res = await axios.post(`${this.URL}${authPath}`, {
                client_id: 'ZjRhMGJmMzllYzFiOTE2MTBjMGE4MjM4',
                client_secret: 'ZDU5MzU5NWJhNTQyNjM1MDQ4NGZmNTlkMmE5ZjIyMjgyMWUx'
            });
        } catch (error) {
            throw new Error(JSON.stringify(error.response.data.error));
        }
        if (res.status !== 200) {
            throw new Error('Personio auth failed. Please try again.');
        }
        this.setAuthToken(res.data.data.token);
    }

    setAuthToken = (token) => {
        this.headers = {
            headers: {
                'Authorization': "Bearer " + token
            }
        };
    }

    getAllPeriods = async (startDate, endDate, employeeIds, offset = 0) => {
        const attendancePath = 'company/attendances';
        let ids = this.formatQueryEmpId(employeeIds), res;
        try {
            res = await axios.get(`${this.URL}${attendancePath}?start_date=${startDate}&end_date=${endDate}&limit=200&offset=${offset}${ids}`, this.headers);
        } catch (error) {
            throw new Error(JSON.stringify(error.response.data.error));
        }
        if (res.status === 200) {
            for (let key in res.data.data) {
                this.periodData.push(res.data.data[key]);
            }
            this.setAuthToken(res.headers.authorization.split(" ")[1]);
            if (res.data.data.length === 200) {
                await this.getAllPeriods(startDate, endDate, employeeIds, offset+200);
            }
            return this.periodData;
        }
    }

    formatQueryEmpId = (data) => {
        let finalString = "", queryString = "&employee[]=1";
        data.forEach(item => {
            queryString = "";
            finalString += `&employees[]=${item}`;
        });
        return `${queryString}${finalString}`;
    }

    deleteExistingPeriods = async (startDate, endDate, employeedIds) => {
        let deletePath = 'company/attendances'
        await this.authenticate();
        let periods = await this.getAllPeriods(startDate, endDate, employeedIds);
        if (periods !== false) {
            for (let period in periods) {
                try {
                    let res = await axios.delete(`${this.URL}${deletePath}/${periods[period].id}`, this.headers);
                    this.setAuthToken(res.headers.authorization.split(" ")[1]);
                } catch (error) {
                    throw new Error(`Error deleting period id ${periods[period].id}. Please try again.`);
                }
            }
        }
    }

    updateAttendance = async (data) => {
        let attendanceData = { attendances: data }, res;
        const attendancePath = 'company/attendances';
        let result = {};
        try {
            res = await axios.post(`${this.URL}${attendancePath}`, attendanceData, this.headers);
            if (res.status === 200) {
                result = {
                    "status": res.status,
                    "message": res.data.data.message
                }
            }
            this.setAuthToken(res.headers.authorization.split(" ")[1]);
        } catch (error) {
            result = {
                "status": 400,
                "message": JSON.stringify(error.response.data.error)
            }
        } finally {
            return result;
        } 
    }
}

export default Personio;
